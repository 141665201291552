<script setup lang="ts">
import { debounce } from 'lodash-es'
import { ValidationProvider } from 'vee-validate'
import { computed, nextTick, ref, watch, onMounted, type PropType } from 'vue'
import { directive as vOnClickaway } from 'vue-clickaway'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import { useInput, baseInputProps, type InputValue } from '/~/composables/base'

interface Option {
  label: string
  [key: string]: any
}

type AsyncSelectInputValue = InputValue | object

interface Emits {
  (e: 'update:value', value: AsyncSelectInputValue | object): void
  (e: 'change', value: AsyncSelectInputValue | object | null): void
  (e: 'input', value: AsyncSelectInputValue): void
  (e: 'blur', component: any): void
  (e: 'clear', component: any | null): void
  (e: 'unmasked', value: any): void
}

const emit = defineEmits<Emits>()

const props = defineProps({
  ...baseInputProps,
  fetch: {
    type: Function as PropType<(input: string) => Promise<Option[]>>,
    default: null,
  },
  look: {
    type: String,
    default: '',
  },
  nolabel: {
    type: Boolean,
    default: true,
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: 'plain/search',
  },
  iconPlain: {
    type: Boolean,
    default: true,
  },
  value: {
    type: [String, Number, Object] as PropType<AsyncSelectInputValue>,
    default: '',
  },
  valueAsObject: {
    type: Boolean,
    default: false,
  },
  mask: {
    type: [Object, String] as PropType<object | string>,
    default: null,
  },
  defaultOptions: {
    type: Array as PropType<Option[]>,
    default: null,
  },
  validation: {
    type: Object as PropType<{ mode?: string; name?: string }>,
    default: () => ({}),
  },
  fetchOnFocus: {
    type: Boolean,
    default: false,
  },
  entryClass: {
    type: String,
    default: 'rounded h-10',
  },
  entryClassExpand: {
    type: String,
    default: 'rounded py-1.5',
  },
  entryClassSelected: {
    type: String,
    default:
      'rounded-sm h-12 text-eonx-neutral-800 border w-full flex items-center p-3',
  },
  inputClass: {
    type: [String, Array] as PropType<string | any[]>,
    default: '',
  },
  labelClass: {
    type: String,
    default: '',
  },
  isStaticData: {
    type: Boolean,
    default: false,
  },
  isFetchingSelectedItem: {
    type: Boolean,
    default: false,
  },
})

const {
  validationProviderRef,
  isFocused,
  validateOnBlur,
  validate,
  onInput,
  syncValue,
  reset,
  validateSilent,
} = useInput(props, emit)

const baseInput = ref<InstanceType<typeof BaseInput>>()
const list = ref<HTMLElement>()
const inputValue = ref<AsyncSelectInputValue>(props.value || '')
const selectedValue = ref<AsyncSelectInputValue>()
const options = ref<Option[]>(props.defaultOptions || [])

const isExpanded = computed(
  () =>
    isFocused.value &&
    (String(inputValue.value).length > 1 || props.isStaticData) &&
    options.value
)

const isLoading = computed(
  () => isFocused.value && String(inputValue.value).length > 0 && !options.value
)

const isEmpty = computed(() => options.value?.length === 0)

const debounceTimeout = computed(() => (props.isStaticData ? 0 : 400))

const fetchOptions = debounce(async (input: AsyncSelectInputValue) => {
  if (typeof props.fetch === 'function') {
    try {
      options.value = await props.fetch(String(input))
    } catch (error) {
      console.error(error)
      options.value = []
    }
  }
}, debounceTimeout.value)

watch(
  () => props.value,
  (newVal) => {
    inputValue.value = newVal || ''
  }
)

function onClickSelected() {
  clear()
  selectedValue.value = null
  emit('update:value', null)
  emit('change', null)
  inputValue.value = ''
  nextTick(() => baseInput.value?.focus())
}

function onSearchFocus() {
  isFocused.value = true
  if ((props.fetchOnFocus && inputValue.value) || props.isStaticData) {
    fetchOptions(inputValue.value)
  }
}

function onSearchBlur(event?: FocusEvent) {
  if (
    event?.relatedTarget &&
    list.value?.contains(event.relatedTarget as Node)
  ) {
    return
  }

  validateOnBlur(inputValue.value)
  emit('blur', inputValue.value)
  isFocused.value = false
}

function onSearchCleared() {
  clear()
  emit('clear', inputValue.value)
}

function onSearchInput(value: AsyncSelectInputValue) {
  onInput(value)

  if (String(value).length > 1) {
    fetchOptions(value)
    isFocused.value = true
  } else {
    clear()
    if (props.isStaticData) fetchOptions(value)
  }
}

function onSelectItem(item: Option) {
  const value = props.valueAsObject ? item : item.label

  emit('update:value', value)
  emit('change', value)
  nextTick(() => {
    inputValue.value = value
    selectedValue.value = item
  })

  if (props.validation?.mode !== 'passive') {
    validate(inputValue.value)
  }

  close()
}

function clear() {
  emit('clear', inputValue.value ? null : undefined)
  options.value = null
  reset()
}

function close() {
  if (isFocused.value) {
    isFocused.value = false
    onSearchBlur()
  }
}

onMounted(() => {
  syncValue(inputValue.value)
  validateSilent()
})
</script>

<template>
  <div v-on-clickaway="close" class="relative">
    <validation-provider
      v-slot="{ errors }"
      v-bind="validation"
      ref="validationProviderRef"
      mode="passive"
      :name="validation.name || label"
      slim
      :detect-input="false"
    >
      <button
        v-if="$slots.inputValueSlot && selectedValue"
        :class="entryClassSelected"
        @click="onClickSelected"
      >
        <slot name="inputValueSlot" :value="selectedValue" />
        <base-icon
          class="absolute right-3 z-10"
          svg="plain/chevron-bottom"
          size="sm"
        />
      </button>
      <base-input
        v-else
        ref="baseInput"
        v-model="inputValue"
        clearable
        :error="isFetchingSelectedItem ? '' : errors[0] || error"
        :icon="icon"
        :icon-plain="iconPlain"
        :look="look"
        :label="label"
        :nolabel="nolabel"
        :placeholder="placeholder"
        :disabled="!fetch || isFetchingSelectedItem || disabled"
        :floated="floated"
        :required="required"
        :loading="isLoading || isFetchingSelectedItem"
        :entry-class="entryClass"
        :input-class="inputClass"
        :label-class="labelClass"
        :mask="mask"
        :name="name"
        :validation="{ vid: `${name} input`, disabled: true }"
        @input="onSearchInput"
        @focus="onSearchFocus"
        @cleared="onSearchCleared"
        @unmasked="emit('unmasked', $event)"
      />
    </validation-provider>

    <transition name="fade">
      <div
        v-show="isExpanded"
        ref="list"
        class="absolute top-full left-0 z-10 max-h-80 w-full overflow-y-auto rounded border bg-default"
        :class="entryClassExpand"
      >
        <slot v-if="isEmpty" name="noOptionsFound">
          <div class="py-1.5 px-3">
            <span class="text-sm">No records were found</span>
          </div>
        </slot>
        <ul v-else class="divide-y divide-eonx-neutral-300">
          <li
            v-for="(item, idx) in options"
            :key="'suburb-' + idx"
            :tabindex="0"
            class="cursor-pointer truncate hover:bg-primary-lighten"
            @click="onSelectItem(item)"
          >
            <slot
              v-if="$slots.option"
              name="option"
              :option="item"
              :idx="idx"
            />
            <div v-else class="py-2 px-3.5">
              {{ item.label }}
            </div>
          </li>
        </ul>
        <slot name="afterOptions" />
      </div>
    </transition>
  </div>
</template>
